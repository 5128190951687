/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, SeparateLine, SeparateLineWrap, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"WoodArt Slovakia"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="css-1q3zv8v --style3 --full --parallax" anim={""} name={"uvod123"} animS={"3"} border={""} fullscreen={true} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8d5bbb9f5b0e44d5a50dc2ca30dbec7c_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8d5bbb9f5b0e44d5a50dc2ca30dbec7c_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8d5bbb9f5b0e44d5a50dc2ca30dbec7c_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8d5bbb9f5b0e44d5a50dc2ca30dbec7c_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8d5bbb9f5b0e44d5a50dc2ca30dbec7c_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8d5bbb9f5b0e44d5a50dc2ca30dbec7c_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8d5bbb9f5b0e44d5a50dc2ca30dbec7c_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/8d5bbb9f5b0e44d5a50dc2ca30dbec7c_s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1390}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1425}} anim={"2"} animS={"5"}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Naša tvorba"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w js-anim  --anim2 pb--60 pt--60" style={{"backgroundColor":"rgba(44,45,54,1)"}} name={"fotogalerie"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/6987fb7b38234d9fa6f079d632dcff58_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/6987fb7b38234d9fa6f079d632dcff58_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/6987fb7b38234d9fa6f079d632dcff58_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/6987fb7b38234d9fa6f079d632dcff58_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/6987fb7b38234d9fa6f079d632dcff58_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/01ed825e0c6b46b1ba94a59bf4feab10_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/01ed825e0c6b46b1ba94a59bf4feab10_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/01ed825e0c6b46b1ba94a59bf4feab10_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/01ed825e0c6b46b1ba94a59bf4feab10_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/01ed825e0c6b46b1ba94a59bf4feab10_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/01ed825e0c6b46b1ba94a59bf4feab10_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/26d88bedf50d461f809694d8bfe720f6_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/26d88bedf50d461f809694d8bfe720f6_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/26d88bedf50d461f809694d8bfe720f6_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/26d88bedf50d461f809694d8bfe720f6_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/26d88bedf50d461f809694d8bfe720f6_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/26d88bedf50d461f809694d8bfe720f6_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w js-anim  --anim2 pb--60 pt--60" name={"fotogalerie"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/511592cfa163403aa5957ebcf9c2ab9c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/511592cfa163403aa5957ebcf9c2ab9c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/511592cfa163403aa5957ebcf9c2ab9c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/511592cfa163403aa5957ebcf9c2ab9c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/511592cfa163403aa5957ebcf9c2ab9c_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/c6af112511734742b9d1fe0094738ba5_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/c6af112511734742b9d1fe0094738ba5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/c6af112511734742b9d1fe0094738ba5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/c6af112511734742b9d1fe0094738ba5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/c6af112511734742b9d1fe0094738ba5_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/0cd72da51bf043f3847749e1bec832bf_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/0cd72da51bf043f3847749e1bec832bf_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/0cd72da51bf043f3847749e1bec832bf_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/0cd72da51bf043f3847749e1bec832bf_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/0cd72da51bf043f3847749e1bec832bf_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39117/0cd72da51bf043f3847749e1bec832bf_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/2de2ddf4ce274247bc14714e83a52b18_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/2de2ddf4ce274247bc14714e83a52b18_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/2de2ddf4ce274247bc14714e83a52b18_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/2de2ddf4ce274247bc14714e83a52b18_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/2de2ddf4ce274247bc14714e83a52b18_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39117/2de2ddf4ce274247bc14714e83a52b18_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/ea082b00e1784c0cb71ae65d6346bd2f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/ea082b00e1784c0cb71ae65d6346bd2f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/ea082b00e1784c0cb71ae65d6346bd2f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/ea082b00e1784c0cb71ae65d6346bd2f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/ea082b00e1784c0cb71ae65d6346bd2f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39117/ea082b00e1784c0cb71ae65d6346bd2f_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/50b46f9db8db40a49d4d4cdd9b77af4e_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/50b46f9db8db40a49d4d4cdd9b77af4e_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/39117/50b46f9db8db40a49d4d4cdd9b77af4e_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/39117/50b46f9db8db40a49d4d4cdd9b77af4e_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/39117/50b46f9db8db40a49d4d4cdd9b77af4e_s=1400x_.jpeg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w js-anim  --anim2 pb--60 pt--60" style={{"backgroundColor":"rgba(44,45,54,1)"}} name={"fotogalerie"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/91f7cc64e604413b9b08ebb1b2f55921_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/91f7cc64e604413b9b08ebb1b2f55921_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/39117/91f7cc64e604413b9b08ebb1b2f55921_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/39117/91f7cc64e604413b9b08ebb1b2f55921_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/39117/91f7cc64e604413b9b08ebb1b2f55921_s=1400x_.jpeg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/400243ca50bf47d3b6f4d9cb57ce3c37_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/400243ca50bf47d3b6f4d9cb57ce3c37_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/400243ca50bf47d3b6f4d9cb57ce3c37_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/400243ca50bf47d3b6f4d9cb57ce3c37_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/400243ca50bf47d3b6f4d9cb57ce3c37_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/16c9fcce209443e6be3dc8e6c686a451_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/16c9fcce209443e6be3dc8e6c686a451_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/16c9fcce209443e6be3dc8e6c686a451_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/16c9fcce209443e6be3dc8e6c686a451_s=860x_.jpg 860w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/42a684f28b8e424caf9df4b7928463f5_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/42a684f28b8e424caf9df4b7928463f5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/42a684f28b8e424caf9df4b7928463f5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/42a684f28b8e424caf9df4b7928463f5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/42a684f28b8e424caf9df4b7928463f5_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/f880fec9f09543628ee1fc11e9f2a8b7_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/f880fec9f09543628ee1fc11e9f2a8b7_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/39117/f880fec9f09543628ee1fc11e9f2a8b7_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/39117/f880fec9f09543628ee1fc11e9f2a8b7_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/39117/f880fec9f09543628ee1fc11e9f2a8b7_s=1400x_.jpeg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/98d38352aa184a53ab8ffa46dd378694_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/98d38352aa184a53ab8ffa46dd378694_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/98d38352aa184a53ab8ffa46dd378694_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/98d38352aa184a53ab8ffa46dd378694_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/98d38352aa184a53ab8ffa46dd378694_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w js-anim  --anim2 pb--60 pt--60" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"fotogalerie"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/0b1451d78dd443eaaebc891153ffee02_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/0b1451d78dd443eaaebc891153ffee02_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/0b1451d78dd443eaaebc891153ffee02_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/0b1451d78dd443eaaebc891153ffee02_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/0b1451d78dd443eaaebc891153ffee02_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/d041c74c8a7e44b4b92cfcffb7a48e8b_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/d041c74c8a7e44b4b92cfcffb7a48e8b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/d041c74c8a7e44b4b92cfcffb7a48e8b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/d041c74c8a7e44b4b92cfcffb7a48e8b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/d041c74c8a7e44b4b92cfcffb7a48e8b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39117/d041c74c8a7e44b4b92cfcffb7a48e8b_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/90db10ed1b3b43b39458c8f00134a3f3_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/90db10ed1b3b43b39458c8f00134a3f3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/90db10ed1b3b43b39458c8f00134a3f3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/90db10ed1b3b43b39458c8f00134a3f3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/90db10ed1b3b43b39458c8f00134a3f3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39117/90db10ed1b3b43b39458c8f00134a3f3_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/4ca7f10e770445af8a93bffde01c2d73_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/4ca7f10e770445af8a93bffde01c2d73_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/4ca7f10e770445af8a93bffde01c2d73_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/4ca7f10e770445af8a93bffde01c2d73_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/4ca7f10e770445af8a93bffde01c2d73_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/3d71dda515434d3f9f6454a7a1ccd6aa_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/3d71dda515434d3f9f6454a7a1ccd6aa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/3d71dda515434d3f9f6454a7a1ccd6aa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/3d71dda515434d3f9f6454a7a1ccd6aa_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/3d71dda515434d3f9f6454a7a1ccd6aa_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39117/3d71dda515434d3f9f6454a7a1ccd6aa_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/a0a99b06b9044685bee347a0c4ef92dc_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39117/a0a99b06b9044685bee347a0c4ef92dc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/a0a99b06b9044685bee347a0c4ef92dc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/a0a99b06b9044685bee347a0c4ef92dc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/a0a99b06b9044685bee347a0c4ef92dc_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39117/a0a99b06b9044685bee347a0c4ef92dc_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"9k8bt69vizc"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"pata"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--left fs--26" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box text-box--left pt--08" style={{"marginTop":0}} content={"Andrej Nagy<br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0,"paddingTop":0}} content={"<a href=\"tel:+421 905 310 613\" style=\"color: var(--black);\">+421 905 310 613</a><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><a href=\"mailto:info.woodartsk@gmail.com\" style=\"color: var(--black);\">info.woodartsk@gmail.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":462}}>
              
              <Image style={{"maxWidth":148,"marginTop":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--right fs--26" content={"Návody a Starostlivosť<br>"}>
              </Title>

              <Text className="text-box text-box--right pt--08" style={{"marginTop":0}} content={"<a href=\"/navod-montaz\" style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Zavesenie hodín</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":0}} content={"<a href=\"/starostlivost\" style=\"color: var(--black); background-color: rgb(255, 255, 255);\">Starostlivosť</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}