/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Divider, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"WoodArt Slovakia"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="css-1yvg9ar css-k7zqbx --style3 --full" anim={""} name={"[[UNIsecname1]]"} animS={"3"} border={""} fullscreen={true} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/b979ae0696f14aeabd8bc91cfbc18d9b_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/b979ae0696f14aeabd8bc91cfbc18d9b_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/b979ae0696f14aeabd8bc91cfbc18d9b_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/b979ae0696f14aeabd8bc91cfbc18d9b_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/b979ae0696f14aeabd8bc91cfbc18d9b_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/b979ae0696f14aeabd8bc91cfbc18d9b_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/b979ae0696f14aeabd8bc91cfbc18d9b_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/b979ae0696f14aeabd8bc91cfbc18d9b_s=3000x_.jpg);
    }
  
background-position: 50% 53%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1390}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1425}} anim={"2"} animS={"5"}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Hodiny"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(44,45,54,1)"}} layout={"l8"} name={"produkt"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/1bdcd83076e64f75b3e6bc4ea6e048b1_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/1bdcd83076e64f75b3e6bc4ea6e048b1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39117/1bdcd83076e64f75b3e6bc4ea6e048b1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39117/1bdcd83076e64f75b3e6bc4ea6e048b1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39117/1bdcd83076e64f75b3e6bc4ea6e048b1_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--white); font-weight: bold;\">Olivové hodiny Matte </span><span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255); font-weight: 700;\">Black</span><br><br>"}>
              </Text>

              <Text className="text-box fs--14" content={"<span style=\"color: var(--white);\">Olivové hodiny Matte Black spájajú prirodzenú krásu olivového dreva s moderným matným čiernym dizajnom. Elegantný a sofistikovaný doplnok, ktorý sa stane dominantou každého priestoru.</span><br>"}>
              </Text>

              <Divider style={{"maxWidth":160}}>
              </Divider>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Drevo: Oliva<br>&nbsp;Rozmer: 40 cm<br>&nbsp;Hmotnosť: 5 kg<br>Povrchová úprava: </span><span style=\"color: rgb(232, 222, 27);\">ODIE´S OIL</span><br>"}>
              </Text>

              <Divider style={{"paddingBottom":0,"marginBottom":0}}>
              </Divider>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: var(--white);\">ID: H1</span><br>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: rgb(37, 255, 0);\">Skladom</span><br>"}>
              </Text>

              <Text className="text-box pt--10" style={{"marginTop":0}} content={"<a href=\"/starostlivost#hodiny\" style=\"color: var(--white);\">Starostlivosť</a>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 pt--06" style={{"marginTop":0}} content={"Objednať<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"pata"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--left fs--26" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box text-box--left pt--08" style={{"marginTop":0}} content={"Andrej Nagy<br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0,"paddingTop":0}} content={"<a href=\"tel:+421 905 310 613\" style=\"color: var(--black);\">+421 905 310 613</a><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><a href=\"mailto:info.woodartsk@gmail.com\" style=\"color: var(--black);\">info.woodartsk@gmail.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":462}}>
              
              <Image style={{"maxWidth":148,"marginTop":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--right fs--26" content={"Návody a Starostlivosť<br>"}>
              </Title>

              <Text className="text-box text-box--right pt--08" style={{"marginTop":0}} content={"<a href=\"/navod-montaz\" style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Zavesenie hodín</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":0}} content={"<a href=\"/starostlivost\" style=\"color: var(--black); background-color: rgb(255, 255, 255);\">Starostlivosť</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}