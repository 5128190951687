/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, SeparateLine, SeparateLineWrap, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"WoodArt Slovakia"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="pt--80" style={{"paddingBottom":0}} name={"ako-bezpecne-zavesit-hodiny"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Ako zavesiť hodiny<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ot3vi6az4c9"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Krok 1"}>
              </Title>

              <Text className="text-box text-box--center" content={"Navŕtajte do steny&nbsp;8mm vrtákom dieru o hĺbke hmoždenky a následne vložte hmoždenku.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Krok 2<br>"}>
              </Title>

              <Text className="text-box text-box--center" content={"Zoberte priloženú sktrutky a zaskrutkujte,<br>tak aby hlavička bola od steny cca 3-4mm.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Krok 3<br>"}>
              </Title>

              <Text className="text-box text-box--center" content={"Hodiny možete zavesiť.<br>Od teraz si môžete užívať svoje vysnívané nové hodiny.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"sy6eu7m9z5"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingBottom":0,"paddingTop":0}} name={"hodiny"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Ako sa starať o Hodiny"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"hodinyl2"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image style={{"maxWidth":164}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/39117/978a6494ccbe48e78faaba9c2db22ef2_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/978a6494ccbe48e78faaba9c2db22ef2_s=660x_.png 660w"} alt={""} src={"https://cdn.swbpg.com/t/39117/978a6494ccbe48e78faaba9c2db22ef2_s=660x_.png"}>
              </Image>

              <Text className="text-box" content={"Použite jemnú, suchú alebo mierne navlhčenú utierku z mikrovlákna na pravidelné utieranie prachu. Vyhnite sa hrubým materiálom, ktoré by mohli poškriabať povrch."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image style={{"maxWidth":164}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/39117/19338af920c848f69103877b2d3d2a04_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/39117/19338af920c848f69103877b2d3d2a04_s=350x_.png"}>
              </Image>

              <Text className="text-box" content={"Použite jemnú, suchú alebo mierne navlhčenú utierku z mikrovlákna na pravidelné utieranie prachu. Vyhnite sa hrubým materiálom, ktoré by mohli poškriabať povrch."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--25 pt--25" name={"lxvqjlifgjs"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":269}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"n3ktqk4xauj"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"pata"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--left fs--26" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box text-box--left pt--08" style={{"marginTop":0}} content={"Andrej Nagy<br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0,"paddingTop":0}} content={"<a href=\"tel:+421 905 310 613\" style=\"color: var(--black);\">+421 905 310 613</a><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><a href=\"mailto:info.woodartsk@gmail.com\" style=\"color: var(--black);\">info.woodartsk@gmail.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":462}}>
              
              <Image style={{"maxWidth":148,"marginTop":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--right fs--26" content={"Návody a Starostlivosť<br>"}>
              </Title>

              <Text className="text-box text-box--right pt--08" style={{"marginTop":0}} content={"<a href=\"/navod-montaz\" style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Zavesenie hodín</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":0}} content={"<a href=\"/starostlivost\" style=\"color: var(--black); background-color: rgb(255, 255, 255);\">Starostlivosť</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}