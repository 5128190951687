exports.components = {
  "component---src-pages-doplnky-js": () => import("./../../../src/pages/doplnky.js" /* webpackChunkName: "component---src-pages-doplnky-js" */),
  "component---src-pages-fotogaleria-js": () => import("./../../../src/pages/fotogaleria.js" /* webpackChunkName: "component---src-pages-fotogaleria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-na-predaj-js": () => import("./../../../src/pages/na-predaj.js" /* webpackChunkName: "component---src-pages-na-predaj-js" */),
  "component---src-pages-navod-montaz-js": () => import("./../../../src/pages/navod-montaz.js" /* webpackChunkName: "component---src-pages-navod-montaz-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-starostlivost-js": () => import("./../../../src/pages/starostlivost.js" /* webpackChunkName: "component---src-pages-starostlivost-js" */),
  "component---src-pages-stoly-js": () => import("./../../../src/pages/stoly.js" /* webpackChunkName: "component---src-pages-stoly-js" */),
  "component---src-pages-sviecky-js": () => import("./../../../src/pages/sviecky.js" /* webpackChunkName: "component---src-pages-sviecky-js" */)
}

