/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"WoodArt Slovakia"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="pb--60 pt--80" name={"njtzt2v7c29"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"O nás<br>"}>
              </Title>

              <Subtitle className="subtitle-box fs--10" content={"<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"WoodArt Slovakia sa zameriava na výrobu luxusných drevených produktov, ktoré spájajú remeselnú precíznosť a moderný dizajn. <br>Každý kus je výsledkom detailného spracovania, využitia prémiových materiálov a dôrazu na estetiku a trvácnosť.\n<br>&nbsp;Naším cieľom je vytvárať unikátne diela, ktoré prinášajú do priestoru eleganciu a prirodzenú krásu. <br>WoodArt Slovakia nie je len značka, je to symbol exkluzivity a jedinečného dizajnu.<br>"}>
              </Text>

              <Image style={{"maxWidth":296}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"n3ktqk4xauj"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"pata"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--left fs--26" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box text-box--left pt--08" style={{"marginTop":0}} content={"Andrej Nagy<br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0,"paddingTop":0}} content={"<a href=\"tel:+421 905 310 613\" style=\"color: var(--black);\">+421 905 310 613</a><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><a href=\"mailto:info.woodartsk@gmail.com\" style=\"color: var(--black);\">info.woodartsk@gmail.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":462}}>
              
              <Image style={{"maxWidth":148,"marginTop":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--right fs--26" content={"Návody a Starostlivosť<br>"}>
              </Title>

              <Text className="text-box text-box--right pt--08" style={{"marginTop":0}} content={"<a href=\"/navod-montaz\" style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Zavesenie hodín</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":0}} content={"<a href=\"/starostlivost\" style=\"color: var(--black); background-color: rgb(255, 255, 255);\">Starostlivosť</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}