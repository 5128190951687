/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, YouTubeAsBackground, ColumnWrap, ColumnWrapper, Title, Image, Text, Divider, Button, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"WoodArt Slovakia"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="--style3 --full" anim={""} name={"[[UNIsecname1]]"} animS={"3"} border={""} fullscreen={true} parallax={false} css={css`
      background-position: 44% 61%;
    `}>
          <YouTubeAsBackground autoplay loop modestbranding mute controls={false} embedId={"3IPnX4ENzSM"} />
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1390}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1425}} anim={"2"} animS={"5"}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Luxusné sviečky<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l4"} name={"3point"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/12445bc54d1d43a4968f42c08d7f761d_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":121}} srcSet={"https://cdn.swbpg.com/t/39117/12445bc54d1d43a4968f42c08d7f761d_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-style: italic;\">Kvalitný prírodný vosk&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/8e0dad07044a461f8f5d5c751bb2f205_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":121}} srcSet={"https://cdn.swbpg.com/t/39117/8e0dad07044a461f8f5d5c751bb2f205_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box" content={"100% Prírodný produkt<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/0809a3a0aaa5496e9e751927e790def4_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":121}} srcSet={"https://cdn.swbpg.com/t/39117/0809a3a0aaa5496e9e751927e790def4_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box" content={"100% originálny výrobok<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pt--20" style={{"backgroundColor":"rgba(44,45,54,1)","paddingBottom":0,"marginTop":0}} name={"2wqwt4muf5c"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--white);\">Svietniky<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(44,45,54,1)"}} layout={"l8"} name={"produkt"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/8db749fd2d4045a09bb72588b20ff7ab_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--white); font-weight: bold;\">Olivový svietnik na 4 sviečky<br></span><br>"}>
              </Text>

              <Text className="text-box fs--14" content={"<span style=\"color: var(--white);\">Elegantný svietnik z olivového dreva, ideálny na sviatočné obdobie. Prírodná kresba dodáva vášmu domovu šarm a teplú atmosféru.</span><br>"}>
              </Text>

              <Divider style={{"maxWidth":160}}>
              </Divider>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: var(--white);\">Drevo: Oliva<br>Rozmer:<br>Hmotnosť:<br>Povrchová úprava:&nbsp;</span><span style=\"color: rgb(232, 222, 27);\">ODIE´S OIL</span><span style=\"color: var(--color-custom-1);\"><br></span>"}>
              </Text>

              <Divider style={{"paddingBottom":0,"height":1}}>
              </Divider>

              <Text className="text-box fs--20" content={"<span style=\"font-weight: bold; color: var(--white);\">ID: S1</span>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: rgb(37, 255, 0);\">Skladom</span>"}>
              </Text>

              <Text className="text-box pt--10" style={{"marginTop":0,"marginBottom":0}} content={"<a href=\"/starostlivost#akosastaratosvietniky1\" style=\"color: var(--white);\">Starostlovosť</a>"}>
              </Text>

              <Button className="btn-box btn-box--sbtn1 btn-box--pbtn2 btn-box--shape4 pt--06" style={{"marginTop":0}} content={"Objednať<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/03b5b2e68e394906906f07e54863750c_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--white); font-weight: bold;\">Adventný svietnik z olivového dreva na 4 sviečky</span><br>"}>
              </Text>

              <Text className="text-box fs--14" content={"<span style=\"color: var(--white);\">Elegantný ručne vyrobený svietnik na štyri sviečky. Prírodná kresba olivového dreva dodá vášmu domovu sviatočný šarm. Ideálny na vianočný stôl.</span><br>"}>
              </Text>

              <Divider style={{"maxWidth":160}}>
              </Divider>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: var(--white);\">Drevo: Oliva<br>Rozmer:<br>Hmotnosť:<br>Povrchová úprava:&nbsp;</span><span style=\"color: rgb(232, 222, 27);\">ODIE´S OIL</span><span style=\"color: var(--color-custom-1);\"><br></span>"}>
              </Text>

              <Divider style={{"paddingBottom":0,"height":1}}>
              </Divider>

              <Text className="text-box fs--20" content={"<span style=\"font-weight: bold; color: var(--white);\">ID: S2</span>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: rgb(37, 255, 0);\">Skladom</span>"}>
              </Text>

              <Text className="text-box pt--10" style={{"marginTop":0,"marginBottom":0}} content={"<a href=\"/starostlivost#akosastaratosvietniky1\" style=\"color: var(--white);\">Starostlovosť</a>"}>
              </Text>

              <Button className="btn-box btn-box--sbtn1 btn-box--pbtn2 btn-box--shape4 pt--06" style={{"marginTop":0}} content={"Objednať<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Vyrobené na Slovensku"} src={"https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=350x_.JPG 350w, https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=660x_.JPG 660w, https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=860x_.JPG 860w, https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/39117/ce0956f4ceec41b8b7d9eda1841372aa_s=2000x_.JPG 2000w"} lightbox={true}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--white); font-weight: bold;\">Svietnik z olivového dreva na 3 sviečky<br></span><br>"}>
              </Text>

              <Text className="text-box fs--14" content={"<span style=\"color: var(--white);\">Elegantný svietnik na tri čajové sviečky, vyrobený z olivového dreva. Jeho unikátna prírodná kresba dodá vášmu interiéru útulnosť a štýl. Ideálny do obývačky alebo na stôl.</span><br>"}>
              </Text>

              <Divider style={{"maxWidth":160}}>
              </Divider>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: var(--white);\">Drevo: Oliva<br>Rozmer:<br>Hmotnosť:<br>Povrchová úprava:&nbsp;</span><span style=\"color: rgb(232, 222, 27);\">ODIE´S OIL</span><span style=\"color: var(--color-custom-1);\"><br></span>"}>
              </Text>

              <Divider style={{"paddingBottom":0,"height":1}}>
              </Divider>

              <Text className="text-box fs--20" content={"<span style=\"font-weight: bold; color: var(--white);\">ID: S3</span>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<span style=\"color: rgb(37, 255, 0);\">Skladom</span>"}>
              </Text>

              <Text className="text-box pt--10" style={{"marginTop":0,"marginBottom":0}} content={"<a href=\"/starostlivost#akosastaratosvietniky1\" style=\"color: var(--white);\">Starostlovosť</a>"}>
              </Text>

              <Button className="btn-box btn-box--sbtn1 btn-box--pbtn2 btn-box--shape4 pt--06" style={{"marginTop":0}} content={"Objednať<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"rgba(44,45,54,1)"}} name={"k4t9c23gx2d"} layout={"l6"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"backgroundColor":"rgba(44,45,54,1)","paddingTop":0,"paddingBottom":0}} name={"kdalskm00es"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--white);\">Luxusné Sviečky</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(44,45,54,1)"}} name={"px6fsgs2v5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">Pripravujeme...</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"txsx157byoo"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"pata"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--left fs--26" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box text-box--left pt--08" style={{"marginTop":0}} content={"Andrej Nagy<br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0,"paddingTop":0}} content={"<a href=\"tel:+421 905 310 613\" style=\"color: var(--black);\">+421 905 310 613</a><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><a href=\"mailto:info.woodartsk@gmail.com\" style=\"color: var(--black);\">info.woodartsk@gmail.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":462}}>
              
              <Image style={{"maxWidth":148,"marginTop":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/39117/eaa38f28fbca451fa61db66b349838cd_s=660x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--right fs--26" content={"Návody a Starostlivosť<br>"}>
              </Title>

              <Text className="text-box text-box--right pt--08" style={{"marginTop":0}} content={"<a href=\"/navod-montaz\" style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Zavesenie hodín</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":0}} content={"<a href=\"/starostlivost\" style=\"color: var(--black); background-color: rgb(255, 255, 255);\">Starostlivosť</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}